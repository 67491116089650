<template>
  <div class="container">
    <div class="search_box">
      <collect-search></collect-search>
    </div>
    <div class="sorts_box" v-if="data.length > 0">
      <div class="sorts">
        <div
          class="sort"
          :class="[{ active: reqQuery.ord == 0 }]"
          @click="changeOrd(0)"
        >
          最新素材
        </div>
        <div
          class="sort"
          :class="[{ active: reqQuery.ord == 1 }]"
          @click="changeOrd(1)"
        >
          最多收藏
        </div>
        <div
          class="sort"
          :class="[{ active: reqQuery.ord == 2 }]"
          @click="changeOrd(2)"
        >
          最多点赞
        </div>
      </div>
    </div>
    <div class="main">
      <my-waterfall :data="data" @loadmore="loadmore">
        <!-- <template #append>
          <div class="user_profile">
            <div class="profile_basic">
              <div class="pic">
                <img :src="$store.state.userInfo.pic ? $store.state.userInfo.pic : '../../assets/img/title_icon.png'" />
              </div>
              <div class="username">{{$store.state.userInfo.username ? $store.state.userInfo.username : '???'}}</div>
            </div>
            <div class="profile_stats">
              <div><strong>0</strong>采集</div>
              <div><strong>0</strong>画板</div>
              <div><strong>0</strong>粉丝</div>
            </div>
          </div>
        </template> -->
      </my-waterfall>
      <no-more :show="noMore && reqQuery.page != 1"></no-more>
    </div>
  </div>
</template>

<script>
import CollectSearch from "@/components/CollectSearch.vue";
import MyWaterfall from '@/components/MyWaterfall';
import NoMore from "@/components/NoMore.vue";
export default {
  name: 'CollectionHome',
  components: {
    CollectSearch,
    MyWaterfall,
    NoMore,
  },
  data () {
    return {
      data: [],
      reqQuery: { cat_id: '', username: '', ord: 0, title: '', use: 1, page: 1 },
      noMore: false,
    }
  },
  methods: {
    getCollectData () {
      this.$service.DEFAULT({
        url: '/auth/ppt/fodder/list',
        method: 'GET',
        params: {
          cat_id: this.reqQuery.cat_id,
          username: this.reqQuery.username,
          ord: this.reqQuery.ord,
          title: this.reqQuery.title,
          use: this.reqQuery.use,
          page: this.reqQuery.page
        },
      }).then(res => {
        if (res.code == 200) {
          if (res.data.res.length == 20) {
            this.noMore = false;
            this.data = res.data.res ? this.data.concat(res.data.res) : this.data.concat([]);
          } else {
            this.noMore = true;
            this.data = res.data.res ? this.data.concat(res.data.res) : this.data.concat([]);
          }
        }
      })
    },
    loadmore () {
      if (this.noMore) return;
      this.reqQuery.page += 1;
      this.getCollectData();
    },
    changeOrd (num) { // 采集排序
      this.reqQuery.ord = num;
      this.reqQuery.page = 1;
      this.data = [];
      this.getCollectData();
    },
  },
  created () {
    this.getCollectData();
  },
}
</script>

<style lang="scss" scoped>
.container {
  box-sizing: border-box;
  width: 100%;
  min-width: 1200px;
  min-height: calc(100vh - 70px);
  padding-top: 0.75em;
  background: #f5f5f5;
}
.search_box {
  width: 90%;
  margin: 0 auto;
}
.sorts_box {
  width: 90%;
  margin: 0.75em auto 0;
  user-select: none;
}
.sorts {
  text-align: center;
}
.sort {
  display: inline-block;
  padding: 0 0.75em 0.1em;
  font-size: 1.2em;
  color: #999;
  cursor: pointer;
  &:hover {
    color: #d71345;
  }
  &.active {
    color: #d71345;
    border-bottom: 3px solid#d71345;
  }
}
.main {
  width: 90%;
  margin: 0 auto;
}
</style>